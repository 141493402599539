import { Swiper } from "swiper";
import { Autoplay, Controller, Navigation } from "swiper/modules";

import { createObserver } from "./create-observer";

document.addEventListener("DOMContentLoaded", () => {
    const kr_swiper_video_selector = "#swiper-video .swiper",
        kr_swiper_video_title_selector = "#swiper-video-title .swiper";
    if (
        !document.querySelector(kr_swiper_video_selector) ||
        !document.querySelector(kr_swiper_video_title_selector)
    ) {
        return;
    }
    const videoSwiperObserver = createObserver(0.5),
        kr_swiper_video_element = document.querySelector(
            kr_swiper_video_selector
        );
    //let kr_swiper_video, kr_swiper_video_title;
    videoSwiperObserver.observe(kr_swiper_video_element);
    kr_swiper_video_element.addEventListener("intersecting", () => {
        if (kr_swiper_video.autoplay && kr_swiper_video.autoplay.run) {
            console.log("autoplay resuming...");
            kr_swiper_video.autoplay.run();
        }
    });
    kr_swiper_video_element.addEventListener("not-intersecting", () => {
        if (kr_swiper_video.autoplay && kr_swiper_video.autoplay.pause) {
            console.log("autoplay pause");
            kr_swiper_video.autoplay.pause();
        }
    });

    function videoSwiperPlayPause() {
        kr_swiper_video_element
            .querySelectorAll("video")
            .forEach((sliderVideo) => {
                const slide = sliderVideo.closest(".swiper-slide");
                if (!slide.classList.contains("swiper-slide-active")) {
                    sliderVideo.pause();
                    return;
                }

                if (
                    sliderVideo.muted &&
                    sliderVideo.autoplay &&
                    sliderVideo.paused
                ) {
                    sliderVideo.play();
                    return;
                }
            });
    }

    function initSwiperVideo() {
        window.kr_swiper_video = new Swiper(kr_swiper_video_selector, {
            modules: [Autoplay, Controller],
            centeredSlides: true,
            slidesPerView: "auto",
            loop: false,
            spaceBetween: 10,
            autoplay: {
                delay: 10000,
            },
            breakpoints: {
                992: {
                    centeredSlides: false,
                    slidesPerView: 1,
                    spaceBetween: 20,
                },
            },
            on: {
                afterInit: videoSwiperPlayPause,
                slideChangeTransitionEnd: videoSwiperPlayPause,
            },
        });
        kr_swiper_video.autoplay.pause();
    }

    function initSwiperVideoTitle() {
        window.kr_swiper_video_title = new Swiper(kr_swiper_video_title_selector, {
            modules: [Navigation, Controller],
            loop: false,
            autoplay: false,
            spaceBetween: 20,
            navigation: {
                nextEl: "#swiper-video-title-next",
                prevEl: "#swiper-video-title-prev",
            },
        });
    }

    const breakpoint_lg = window.matchMedia("(min-width: 992px)");

    initSwiperVideo();
    if (breakpoint_lg.matches) {
        initSwiperVideoTitle();
        kr_swiper_video.controller.control = kr_swiper_video_title;
        kr_swiper_video_title.controller.control = kr_swiper_video;
    }

    breakpoint_lg.addEventListener("change", () => {
        if (breakpoint_lg.matches) {
            kr_swiper_video.destroy(false, false);
            kr_swiper_video_title.destroy(false, false);
            initSwiperVideo();
            initSwiperVideoTitle();
            kr_swiper_video.controller.control = kr_swiper_video_title;
            kr_swiper_video_title.controller.control = kr_swiper_video;
            return;
        }
        kr_swiper_video.destroy(false, false);
        kr_swiper_video_title.destroy(false, false);
        initSwiperVideo();
    });
});
